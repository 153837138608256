
export const usePageData = () => {

  const transform = (response) => {
    const { sanitize } = useUtils();
    return {
      id: response.data.id,
      title: sanitize(response.data.attributes.title),
      slug: sanitize(response.data.attributes.slug),
      heroHeader: sanitize(response.data.attributes.heroHeader),
      displayHeroHeader: sanitize(response.data.attributes.displayHeroHeader),
      blocks: sanitize(response.data.attributes.blocks.map((element) => {
        const result = {
          ...element,
          component: element.__component,
        }
        delete result.__component;
        return result;
      })),
      brochureRibbon: sanitize(response.data.attributes.brochureRibbon),
      brochureRibbonTags: sanitize(response.data.attributes.tagsArray),
      jumpToTop: sanitize(response.data.attributes.jumpToTop),
      showChatService: sanitize(response.data.attributes.showChatService),
      seo: sanitize(response.data.attributes.seo),
      parentPage: response.data.attributes.parentPage,
      publishedAt: sanitize(response.data.attributes.publishedAt),
      showPromoBanner: response.data.attributes.showPromoBanner,
      showTopNavMenu: response.data.attributes.showTopNavMenu,
      showFooterMenu: response.data.attributes.showFooterMenu,
    }
  }
  
  const getPages = async (deep = true) => {    
    const {find} = useStrapiAsyncData();
    const url = deep ? 'pages?populate=deep' : 'pages';
    
    return find(url, transform);
  }

  const getPageById = async (id, deep = true) => {    
    const {findOne} = useStrapiAsyncData();
    const url = 'pages';
    const params = {
      id: id,
      populate: deep ? '?populate=deep' : ''
    }
    return findOne(url, params, transform);
  }

  const getPageBySlug = async (slug) => {    
    const {findOneBySlug} = useStrapiAsyncData();
    const url = 'pages';
    const params = {
      slug: slug,
      entity: 'pages'
    }
    return findOneBySlug(url, params, transform);
  }

 
  
  return {
    getPages,
    getPageById,
    getPageBySlug
  }
}