export default defineNuxtRouteMiddleware(async (to, from) => {
  const { getPageBySlug } = usePageData();
  const { getRawPageBySlug } = useRawPageData();
  const { getRecPageBySlug } = useLeadGenRecData();
  let pageType = 'page';
  let pageData = null;

  // Removing the last traling slash from the URL
  if (to.params.slug.length > 1 && to.params.slug[to.params.slug.length - 1] === '') {
    to.params.slug = to.params.slug.slice(0, -1);
  }

  if (to.params.slug.length < 2) {    
    const { data: pageDataResponse, error: pageError } = await getPageBySlug(to.params.slug);
    if (!pageError.value) {
      pageData = pageDataResponse;
      if (pageData.value && pageData.value.parentPage.data !== null) {
        throw createError({ statusCode: 404, statusMessage: 'Page not found!' });
      }
    } else {
      const { data: rawData, error: rawError } = await getRawPageBySlug(to.params.slug);
      if (!rawError.value) {
        pageType = 'raw';
        pageData = rawData;
      } else {
        const { data: leadData, error: leadError } = await getRecPageBySlug(to.params.slug);
        if (!leadError.value) {
          pageType = 'rec';
          pageData = leadData;
        } else {
          throw createError({ statusCode: 404, statusMessage: 'Page not found!' });
        }
      }
    }
  } else {
    const { data: pageDataResponse, error: pageError } = await getPageBySlug(to.params.slug[1]);
    if (!pageError.value) {
      pageData = pageDataResponse;
      if (pageData.value && pageData.value.parentPage.data !== null) {
        const { data: parent, error: parentError } = await getPageBySlug(pageData.value.parentPage.data.attributes.slug);
        if (parentError.value || (parent.value.id !== pageData.value.parentPage.data.id)) {
          throw createError({ statusCode: 404, statusMessage: 'Page not found!' });
        }
      } else {        
        throw createError({ statusCode: 404, statusMessage: 'Page not found!' });
      }
    } else {
      throw createError({ statusCode: 404, statusMessage: 'Page not found!' });
    }
  }
  
  setPageLayout(pageType);
  to.pageType = pageType;
  to.pageData = pageData;
});
